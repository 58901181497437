import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Pages/Home.js';
import Terms from './Pages/Terms.js';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/terms" component={Terms} />
      </Switch>
    </Router>
  );
}

export default App;
